import { getCurrentInstance } from 'vue';
import Vuex, { Store } from 'vuex';
import { AxiosError } from 'axios';
import smartlookClient from 'smartlook-client';
import * as Sentry from '@sentry/browser';
import { ApiUser, getUser } from '@/api/shopify/user';
import type { ProductInfo, CurrentProvider, StoreProduct } from '@extTypes/pod/productInfo';

const MAX_FLOW_DATA_AGE_MILLISECONDS = 259_200 * 1000; // 3 days
let storeInstance: Store<StoreState>;
interface StoreState {
    flowId: string;
    user: ApiUser | null;
    store: string;
    productInfo: ProductInfo | null;
    storeProduct: StoreProduct | null;
    forDesignTemplatesMode: boolean;
    currentProvider: CurrentProvider;
    enabledProviders: Map<string, string>;
    printAreaSectorStep: number;
}

interface LocalStorageFlowData extends StoreState {
    savedOn: number;
}

const removeOldFlows = () => {
    const keys = Object.keys(localStorage);
    for (const key of keys) {
        if (key.startsWith('flowId_')) {
            try {
                const flowData: LocalStorageFlowData = JSON.parse(localStorage.getItem(key) || '');
                if (flowData && Date.now() - flowData.savedOn > MAX_FLOW_DATA_AGE_MILLISECONDS) {
                    localStorage.removeItem(key);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
};

const updateLocalStorage = (state: StoreState) => {
    if (state.flowId) {
        const data = {
            flowId: state.flowId,
            productInfo: state.productInfo,
            storeProduct: state.storeProduct,
            forDesignTemplatesMode: state.forDesignTemplatesMode,
            enabledProviders: state.enabledProviders,
            printAreaSectorStep: state.printAreaSectorStep,
            savedOn: Date.now()
        };
        const json = JSON.stringify(data);

        // Try up to 3 times to save the flow data
        // Local storage can become full, don't throw in that case
        for (let n = 0; n < 3; n++) {
            try {
                localStorage.setItem(`flowId_${state.flowId}`, json);
                break;
            } catch {
                removeOldFlows();
            }
        }
    }
};

export const buildStore = async (flowId: string | null = null) => {
    let user: ApiUser | null = null;
    let productInfo: ProductInfo | undefined;
    let storeProduct: StoreProduct | undefined;
    const currentProvider: CurrentProvider | null = null;
    let shop = '';

    try {
        user = await getUser();
    } catch (_error) {
        const error = _error as AxiosError;
        if (error.request.status !== 401) {
            window.captureException(error as Error);
        } else {
            shop = error.request?.response?.shop;
        }
    }

    // Set user for Sentry logging
    if (user) {
        smartlookClient.identify(user.customilyUser.Id, {
            name: user.customilyUser.Name,
            username: user.customilyUser.Username
        });

        Sentry.setUser({
            username: user.customilyUser.Username,
            id: user.customilyUser.Id.toString()
        });

        Sentry.setContext('store', {
            store: user.client_actual_domain
        });
        if (process.env.NODE_ENV !== 'development') {
            if (!user.customilyUser.UserImpersonatedFromId) {
                // Start session recording only for genuine logins
                window.rumStartRecording();
            } else {
                // Stop recording just in case
                window.rumStopRecording();
            }

            window.rumSetUser({
                id: user.customilyUser.Id,
                name: user.customilyUser.Username,
                impersonatedFrom: user.customilyUser.UserImpersonatedFromId,
                createdDate: user.customilyUser?.CreatedDate || ''
            });
        }
    }

    // Load store data from local storage
    let flowData: StoreState | null = null;
    if (flowId) {
        try {
            const jsonData = localStorage.getItem(`flowId_${flowId}`);
            if (jsonData) flowData = JSON.parse(jsonData);
        } catch (error) {
            window.captureException(error as Error);
        }
    }

    const store = new Vuex.Store<StoreState>({
        state: {
            user: user,
            store: user?.client_actual_domain || shop || '',
            flowId: '',
            productInfo: productInfo || null,
            currentProvider: currentProvider! || null,
            storeProduct: storeProduct || null,
            forDesignTemplatesMode: false,
            printAreaSectorStep: 0,
            enabledProviders: new Map<string, string>(),
            ...flowData
        },
        mutations: {
            setUser(state, user: ApiUser) {
                state.user = { ...state.user, ...user };
            },
            setStore(state, store: string) {
                state.store = store;

                Sentry.setContext('store', {
                    store: store
                });
            },
            setProductInfo(state, productInfo: ProductInfo | null) {
                state.productInfo = productInfo;
                updateLocalStorage(state);
            },
            setStoreProduct(state, storeProduct: StoreProduct) {
                state.storeProduct = storeProduct;
                updateLocalStorage(state);
            },
            setDesignTemplatesMode(state, forDesignTemplatesMode: boolean) {
                state.forDesignTemplatesMode = forDesignTemplatesMode;
                updateLocalStorage(state);
            },
            setEnabledProviders(state, enabledProviders) {
                state.enabledProviders = enabledProviders;
                updateLocalStorage(state);
            },
            setFlowId(state, flowId: string) {
                state.flowId = flowId;
                updateLocalStorage(state);
            },
            setPrintAreaSectorStep(state, step) {
                state.printAreaSectorStep = step;
            },
            setCurrentProvider(state, currentProvider: CurrentProvider) {
                state.currentProvider = currentProvider;
                updateLocalStorage(state);
            }
        },
        getters: {
            productInfo: (state) => state.productInfo
        }
    });

    return store;
};

/**
 * Method that returns instance of current vuex store,
 * this can be used inside composition api based components
 * or utils function outside a component
 * @returns Vuex.Store<StoreState>
 */
export const useStore = (): Store<StoreState> => {
    const vueInstance = getCurrentInstance();
    return vueInstance?.proxy.$store as Store<StoreState>;
};

export const getStoreInstance = async (flowId?: string | null): Promise<Store<StoreState>> => {
    if (!storeInstance) storeInstance = await buildStore(flowId);
    return storeInstance;
};
