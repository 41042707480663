<script setup lang="ts">
import { getProgress } from '@/api/shopify/optionSets';
import { onMounted, ref } from 'vue';

const props = defineProps<{
    taskIdentifier: string;
}>();

const showLoader = ref(true);
const progress = ref<number | null>(0);
const updateProgress = async () => {
    progress.value = await getProgress(props.taskIdentifier);
    if (progress.value === 100) {
        onProgressComplete();
    } else {
        setTimeout(updateProgress, 3000);
    }
};

const onProgressComplete = () => {
    showLoader.value = false;
};

onMounted(() => {
    updateProgress();
});
</script>
<template>
    <div>
        <h4 class="h5 text-center mb-3">{{ `Creating option set ${progress}%` }}</h4>
        <div v-if="showLoader" class="justify-content-center" style="display: flex">
            <b-spinner variant="primary" label="" />
        </div>
        <p v-if="!showLoader" class="text-center">
            Was completed successfully and can be found in the <b>Option Sets</b> section of your store.
        </p>
    </div>
</template>
