import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faTriangleExclamation, faCopy, faEye, faX, faCirclePlus, fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';

/* add icons to the library */
library.add(faTriangleExclamation);
library.add(faCopy);
library.add(faEye);
library.add(faX);
library.add(fas);
library.add(...(Object.values(far) as any[]));
library.add(faCirclePlus);

Vue.component('font-awesome-icon', FontAwesomeIcon);
